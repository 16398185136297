import api from "../api";
/* eslint-disable */
export default {
  namespaced: true,
  state: {
     PrivateBusRequests: [],
     PrivateBusDummyRides: [],

  },
  getters: {
    getPrivateBusRequests(state) {
      return state. PrivateBusRequests;
    }, getPrivateBusDummyRides(state) {
      return state.PrivateBusDummyRides;
    },
  },
  mutations: {
    SetPrivateBusRequests(state, data) {
      state. PrivateBusRequests = [];
      state. PrivateBusRequests = data.map(requests => ({
        ...requests,
        requestDate: new Date(requests.requestDate),
        returnDate2: requests.returnDate,
        rideDate2: requests.rideDate,
        returnDate:requests.returnDate==null?requests.returnDate: new Date(requests.returnDate),
        rideDate: new Date(requests.rideDate),
        serial: Number(requests.serial),
        busTypeId: Number(requests.busTypeId),
        price: Number(requests.price),
        userId: Number(requests.userId)
    
      }));
    },
    SetPrivateBusDummyRides(state, data) {
      state. PrivateBusDummyRides = [];
      state. PrivateBusDummyRides = data.map(requests => ({
        ...requests,
        rideDate: new Date(requests.rideDate),

        id: Number(requests.id),
        
    
      }));
    },
  },
  actions: {
    getPrivateBusRequests({ commit },data) {
      return new Promise((resolve, rej) => {
      api()
        .post(
          `privateBusRequests/getPrivateBusRequests`,
          data,
          {
            headers: {
              Authorization: localStorage.getItem("JwtToken"),
            },
          }
        )
        .then((res) => {
          resolve(res);

          commit("SetPrivateBusRequests", res.data);
        })
        .catch((err) => resolve(err.response));});
    },
    getPrivateBusDummyRides({ commit }) {
      return new Promise((resolve, rej) => {
      api()
        .post(
          `privateBusRequests/getPrivateBusDummyRides`,
          {},
          {
            headers: {
              Authorization: localStorage.getItem("JwtToken"),
            },
          }
        )
        .then((res) => {
          resolve(res);

          commit("SetPrivateBusDummyRides", res.data);
        })
        .catch((err) => resolve(err.response));});
    },
    
    PaidPrivatBus({ dispatch }, data) {
      return new Promise((resolve, rej) => {
        api()
          .post(`privateBusRequests/PaidPrivatBus`, data, {
            headers: {
              Authorization: localStorage.getItem("JwtToken"),
            },
          })
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },
    addRidePrice({ dispatch }, data) {
      return new Promise((resolve, rej) => {
        api()
          .post(`privateBusRequests/addRidePrice`, data, {
            headers: {
              Authorization: localStorage.getItem("JwtToken"),
            },
          })
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },
    rejectRequest({ dispatch }, data) {
      return new Promise((resolve, rej) => {
        api()
          .post(`privateBusRequests/rejectRequest`, data, {
            headers: {
              Authorization: localStorage.getItem("JwtToken"),
            },
          })
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },   
    unRejectRequest({}, data) {
      return new Promise((resolve, rej) => {
        api()
          .post(`privateBusRequests/unRejectRequest`, data, {
            headers: {
              Authorization: localStorage.getItem("JwtToken"),
            },
          })
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },
    addRequestPrivateBus({ dispatch }, data) {
      return new Promise((resolve, rej) => {
        api()
          .post(`privateBusRequests/addRequestPrivateBus`, data, {
            headers: {
              Authorization: localStorage.getItem("JwtToken"),
            },
          })
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            resolve(err.response);
          });
      });
    },


  },
};

import api from "../../api";
/* eslint-disable */
export default {
  namespaced: true,
  state: {
    SearchUsersHistories: [],
   
  },
  getters: {
    getSearchUsersHistories(state) {
      return state.SearchUsersHistories;
    }
  },
  mutations: {
    SetSearchUsersHistories(state, data) {
      state.SearchUsersHistories = [];
      state.SearchUsersHistories = data.map(ride => ({
        ...ride,
        searchTime: new Date(ride.searchTime),
      
      }));
    }
  },
  actions: {
    getSearchUsersHistories({ commit },data) {
      return new Promise((resolve, rej) => {
      api()
        .post(
          `searchUsersHistories/getSearchUsersHistories`,
          data,
          {
            headers: {
              Authorization: localStorage.getItem("JwtToken"),
            },
          }
        )
        .then((res) => {
          resolve(res);
          commit("SetSearchUsersHistories", res.data);
        })
        .catch((err) => console.log(err));});
    },   
   
  },
};
